<template>
  <div>
    <Header></Header>
    <div class="jsfn1">
      <img class="jsfnA1" src="@/assets/jsfn.png" alt="" />
      <div class="jsfnB1">大数据</div>
      <div class="jsfnC1">BIG DATA</div>
      <div class="jsfnD1">
        &emsp;&emsp;大数据时代的来临给政府管理和企业生产管理带来新的发展机遇，同时也伴随着严峻的挑战。能否利用好大数据实现政府职能归集共享，流程再造，是政府能否完成数字政府转型的关键；<br />
        &emsp;&emsp;能否有效地利用企业内部和外部的数据洞察企业的运营状态和市场趋势等，是企业能否提高灵活性，并在未来的敏捷环境中规避风险，最终胜出的重要因素。
      </div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">大数据分析</div>
        <div class="bodyTitle">
          <div class="product">BIG DATA ANALYTICS</div>
          <div class="more">
            <div class="gdal">查看详情</div>
            <div>></div>
          </div>
        </div>
      </div>
      <div>
        <div class="jsfny11">
          <img class="jsfny1" src="@/assets/jsfnd1.png" alt="" />
        </div>
        <div class="fontA1">
          &emsp;&emsp;如何有效地集成来自不同系统、不同类型且不断变化的大量异构数据，
          是企业在利用大数据上面临的一大难题；
          而如何灵活地管理和配置数据集、分析算法以及可视化结果，
          是大数据技术在企业实际应用效果的保证。
        </div>
        <div class="fontA11">
          &emsp;&emsp;网新的大数据分析能够立足于企业的数据现状和实际需求，
          以“产品+服务”的形式， 提供从数据采集、数据存储、数据处理、
          数据分析到数据应用的包含大数据全生命周期的一站式解决方案，
          为用户提供灵活、快速的大数据服务，
          包括制定数据策略、可定制化的数据集成方案、数据基础设施的实施，
          以及强大的数据分析平台。
        </div>
      </div>
      <div>
        <div class="cpszh">数据可视化</div>
        <div class="bodyTitle">
          <div class="product">LAW ENFORCEMENT</div>
          <div class="more">
            <div class="gdal">查看详情</div>
            <div>></div>
          </div>
        </div>
      </div>
      <div class="jsfny11">
        <img class="jsfny1" src="@/assets/jsfnd2.png" alt="" />
      </div>
      <div class="fontA1">
        &emsp;&emsp;然茜科技数据可视化方案基于最新一代的互联网前端技术—HTML5和CSS3，
        针对大型屏幕展示面积大、视角宽广等特点，结合业务需求进行高度定制化，
        从而制作出精美、直观，有视觉震撼力的展示界面。
      </div>
      <div class="fontA11">
        &emsp;&emsp;主要应用于政府信息、零售、物流、电力、水利、环保、交通领域等，
        既可以帮助用户宏观掌控业务数据情况并发现、诊断问题，又可以很好的树立企业形象。
        目前该方案已在政府机构、电子商务、交易平台等领域拥有多个成功案例，并且得到客户一致好评。
      </div>
      <div>
        <div class="cpszh">企业数据集企业数据集成与分析</div>
        <div class="bodyTitle">
          <div class="product">THE ENTERPRISE DATA</div>
          <div class="more">
            <div class="gdal">查看详情</div>
            <div>></div>
          </div>
        </div>
      </div>
      <div class="jsfny11">
        <img class="jsfny1" src="@/assets/jsfnd3.png" alt="" />
      </div>
      <div class="fontA1">
        &emsp;&emsp;然茜科技数据分析平台是一个通用的企业数据分析平台，
        基于数据服务总线（Data Service Bus）概念，
        其核心功能在于提供灵活的数据集配置与管理、
        可插拔的大数据计算引擎和分析算法管理，以及先进的可视化分析功能。
        为将数据分析加入到企业的常规业务流程中，
      </div>
      <div class="fontA1">
        &emsp;&emsp;数据分析平台针对数据分析师和企业高管等非IT技术人员，
        提供简洁的界面和操作风格，方便的分析任务配置与查看，
        帮助提高数据分析的效率。
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.bodyTitle {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.gdal {
  margin-right: 4px;
  font-size: 14px;
  color: #7d7d7d;
}
.jsfny11 {
  display: flex;
  justify-content: center;
}
.jsfny1 {
  margin: 24px 32px;
}
.fontA1 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 0 32px;
  line-height: 40px;
}
.fontA11 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 20px 32px;
  line-height: 40px;
}
.fontA2 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 24px 24px 0 32px;
  line-height: 40px;
}
.jsfnr2 {
  margin: 24px 32px 0 32px;
}
.jsfn1 {
  position: relative;
}
.jsfnA1 {
  width: 100%;
}
.jsfnB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnD1 {
  position: absolute;
  top: 360px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 40px;
  letter-spacing: 1px;
}
</style>
